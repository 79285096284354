import { graphql } from 'gatsby'
import React, {useEffect, useState} from 'react'

import SEO from '../components/SEO'
import Layout from '../components/Layout'
import VideoList from '../components/VideoList'

const Dikt = ({ data }) => {
  const [scrollTop, setScrollTop] = useState(0)
  const [intervalId, setIntervalId] = useState(0)

  function onScroll(e) {
    const windowY = window.scrollY;
    if (intervalId > 0) {
      setScrollTop(windowY)
    }
  }
  useEffect(() => {

    window.addEventListener('scroll', onScroll)

    if (intervalId > 0) {
      window.scroll({
        top: scrollTop - 200
      })
    }
    if (scrollTop === 0 && intervalId === 1) {
      setIntervalId(0)
    }
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
    
  }, [scrollTop, intervalId])

  useEffect(() => {

    const options = {
      rootMargin: '0px',
      threshold: 0.5
    }
    const callback = (entries, observer) => {
      entries.forEach(entry => {
        const { y } = entry.boundingClientRect;
        
        if (!entry.isIntersecting) {
          entry.target.classList.remove('animation-in')
          if (y < 0) {
            entry.target.classList.add('animation-out')
            setTimeout(() => {
              entry.target.classList.remove('animation-out')
            }, 1000)
          }
          return
        }
        
        entry.target.classList.add('animation-in')
        
      })
    };
    let observer = new IntersectionObserver(callback, options);
    document.querySelectorAll('.video-list-dikt').forEach(target => {
      observer.observe(target);
    })
  }, [])
  
  const backTopTop = () => {
    setScrollTop(window.scrollY)
    setIntervalId(1)
  }
  return (
    <>
      <SEO title="Dikt" />
      <Layout>
        <main className="text-white max-w-md mx-auto p-3 pt-24">
          {data.videos.nodes.map((video, index) => {
            return (
              <VideoList
                key={video.shortname}
                  image={video.cover.asset.gatsbyImageData}
                  title={video.title}
                  direction={index % 2 == '0' ? 'left' : 'right'}
                  
                  pathRemix={`${video.shortname}/remix`}
                  pathNormal={video.shortname}
                />
            )
          })}

          <div className="flex justify-center pb-24 pt-8">
            <button
              className="bg-none text-xl text-brand underline focus:outline-none hover:no-underline"
              onClick={backTopTop}
              type="button"
            >
              Til toppen
            </button>
          </div>
        </main>
      </Layout>
    </>
  )
}

export const query = graphql`
  query VideoList {
    videos: allSanityDikt(sort: { fields: [order], order: ASC }) {
      nodes {
        order
        title
        shortname
        cover {
          asset {
            gatsbyImageData(width: 297, fit: FILLMAX, placeholder: BLURRED)
          }
        }
      }
    }
  }
`

export default Dikt




