import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
const VideoList =  (props) => {
  const { image, title, direction, pathRemix, pathNormal } = props;

  return (
    <div className="mb-12 video-list-dikt" id={title}>
      <div
        className={`flex flex-col ${
          direction === 'left' ? 'items-left text-left' : 'items-end text-right'
        }`}
      >
        <h2 className="uppercase text-3xl mb-2 max-w-1.5xl font-brand">
          {title}
        </h2>
      </div>
      <div
        className={`relative ${
          direction === 'left' ? 'text-left' : 'text-right'
        }`}
      >
        <GatsbyImage className="inline-block max-w-1.5xl sm:max-w-2.0xl video-list-dikt__img" image={image} alt="title" />
        
        <div
          className={`bg-brand w-44 px-4 py-5 text-2.5xl font-demi uppercase flex flex-col justify-center space-y-2 items-center absolute top-1/2 video-list-dikt__links ${
            direction === 'left' ? 'right-0' : 'left-0'
          }`}
        >
         
          <Link to={`/dikt/${pathNormal}`} className="text-black">
            SE DIKT
          </Link>
          <Link to={`/dikt/${pathRemix}`} className="text-black">
            Lag REmix
          </Link>
          
          </div>
      </div>
    </div>
  )
}

export default VideoList
